<template>
  <div>
    <!-- Task Card Header -->
    <b-card class="card mb-1" title="Edit Data Promo">
      <form @submit.prevent="submitForm()">
        <div class="mt-2">
          <b-row>

            <b-col md="6" class="mb-1">
              <b-form-group label="Foto" :invalid-feedback="formErrors.image ? formErrors.image[0] : ''"
                :state="formErrors.image ? false : undefined">
                <img :src="blobImage ? blobImage : image" style="max-width: 100%; max-height: 200px;" class="mb-1">
                <b-form-file v-model="formData.image" placeholder="Pilih Foto"
                  :state="formErrors.image ? false : undefined" @change="onFileChange" />
              </b-form-group>
            </b-col>
            <b-col md="6" class="mb-1">
              <b-form-group label="Kode Promo" :invalid-feedback="formErrors.code ? formErrors.code[0] : ''"
                :state="formErrors.code ? false : undefined">
                <b-form-input v-model="formData.code" placeholder="Masukkan Kode Promo"
                  :state="formErrors.code ? false : undefined" />
              </b-form-group>
            </b-col>
            <b-col lg="6" class="mb-1">
              <label class="d-block">Dapat Dilihat User?</label>
              <div class="d-flex align-items-center">
                <b-form-checkbox v-model="formData.is_visible" name="check-button" switch inline class="mr-0"
                  :value="1" />
                <span class="line-height-small"> Ya </span>
              </div>
              <small id="emailHelp" class="form-text text-muted">Promo akan terlihat oleh user jika diaktifkan</small>
            </b-col>
            <!-- Judul Lengkap -->
            <b-col md="6" class="mb-1">
              <b-form-group label="Judul Promo" :invalid-feedback="formErrors.title ? formErrors.title[0] : ''"
                :state="formErrors.title ? false : undefined">
                <b-form-input v-model="formData.title" placeholder="Masukkan Judul Promo"
                  :state="formErrors.title ? false : undefined" />
              </b-form-group>
            </b-col>

            <b-col md="12" class="mb-1">
              <b-form-group label="Deskripsi" :invalid-feedback="formErrors.description ? formErrors.description[0] : ''"
                :state="formErrors.description ? false : undefined">
                <b-form-textarea v-model="formData.description" placeholder="Masukkan Deskripsi"
                  :state="formErrors.description ? false : undefined" />
              </b-form-group>
            </b-col>

            <b-col md="6" class="mb-1">
              <b-form-group label="Tanggal Mulai" :invalid-feedback="formErrors.start_at ? formErrors.start_at[0] : ''"
                :state="formErrors.start_at ? false : undefined">
                <flat-pickr v-model="formData.start_at" class="form-control" :class="{
                  'is-invalid': formErrors.start_at,
                }" :config="{
  altInput: true,
  altFormat: 'j F Y (H:i)',
  dateFormat: 'Y-m-d H:i',
  minDate: 'today',
  enableTime: true,
  time_24hr: true,
}" />
              </b-form-group>
            </b-col>

            <b-col md="6" class="mb-1">
              <b-form-group label="Tanggal Selesai" :invalid-feedback="formErrors.end_at ? formErrors.end_at[0] : ''"
                :state="formErrors.end_at ? false : undefined">
                <flat-pickr v-model="formData.end_at" class="form-control" :class="{
                  'is-invalid': formErrors.end_at,
                }" :config="{
  altInput: true,
  altFormat: 'j F Y (H:i)',
  dateFormat: 'Y-m-d H:i',
  minDate: 'today',
  enableTime: true,
  time_24hr: true,
}" />
              </b-form-group>
            </b-col>


            <b-col md="12" class="mb-1">
              <b-form-group v-slot="{ ariaDescribedby }" label="Tipe Promo" :state="formErrors.type ? false : null"
                :invalid-feedback="formErrors.type ? formErrors.type[0] : ''">
                <b-form-radio-group id="radio-group-type" v-model="formData.type" :options="[
                  { value: 'Persentase', text: 'Persentase' },
                  { value: 'Nominal', text: 'Nominal' }
                ]" :aria-describedby="ariaDescribedby" name="radio-option-type" />
              </b-form-group>
            </b-col>

            <b-col md="12" class="mb-1" v-if="formData.type == 'Nominal'">
              <b-form-group label="Nominal (Rupiah)" :invalid-feedback="formErrors.amount ? formErrors.amount[0] : ''"
                :state="formErrors.amount ? false : undefined">
                <b-form-input v-model="formData.amount" placeholder="Nominal (Rupiah)" type="number"
                  :state="formErrors.amount ? false : undefined" />
              </b-form-group>
            </b-col>

            <b-col md="6" class="mb-1" v-if="formData.type == 'Persentase'">
              <b-form-group label="Persentase (%)" :invalid-feedback="formErrors.amount ? formErrors.amount[0] : ''"
                :state="formErrors.amount ? false : undefined">
                <b-form-input v-model="formData.amount" placeholder="Persentase (%)" type="number"
                  :state="formErrors.amount ? false : undefined" />
              </b-form-group>
            </b-col>

            <b-col md="6" class="mb-1" v-if="formData.type == 'Persentase'">
              <b-form-group label="Maksimal Diskon (Rupiah)" :invalid-feedback="formErrors.max ? formErrors.max[0] : ''"
                :state="formErrors.max ? false : undefined">
                <b-form-input v-model="formData.max" placeholder="Maksimal Diskon (Rupiah)" type="number"
                  :state="formErrors.max ? false : undefined" />
              </b-form-group>
            </b-col>

          </b-row>

          <div>
            <b-button variant="primary" class="float-right" type="submit">
              <div class="d-flex align-items-center">
                <b-spinner v-show="isLoading" class="mr-1" variant="light" small />
                Simpan
              </div>
            </b-button>
          </div>
        </div>
      </form>
    </b-card>
  </div>
</template>

<script>
// Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BSpinner,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormText,
  BFormFile,
  BFormCheckbox,
  BFormRadioGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Utils
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { avatarText } from '@core/utils/filter'
import { reqUpdatePromo, reqGetPromo } from '@/api/admin/promo'
import { checkAuthorizeRole } from "@/auth/utils";

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormText,
    vSelect,
    flatPickr,
    BFormFile,
    BFormCheckbox,
    BFormRadioGroup
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: false,
      blobImage: '',
      image: '',

      formErrors: {},

      formData: {
        code: '',
        is_visible: 0,
        title: '',
        image: null,
        type: 'Persentase',
        amount: 0,
        nominal: 0,
        max: 0,
        start_at: '',
        end_at: ''
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.showPassword ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  mounted() {
    this.getPromo()
  },
  watch: {
  },
  methods: {
    avatarText,
    checkAuthorizeRole,
    onFileChange(e) {
      const file = e.target.files[0]
      this.blobImage = URL.createObjectURL(file)
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    getPromo() {
      reqGetPromo(this.$route.params.id).then(res => {
        this.image = res.data.data.image
        this.formData = res.data.data
        this.formData.image = null;
      })
        .catch(error => {
          this.isLoading = false
          this.formErrors = error.response.data.errors

          this.showToast(
            'danger',
            'AlertTriangleIcon',
            'Gagal',
            error.response.data.message || 'Gagal mengambil pomo',
          )
        })
    },
    validateForm() {
      const isValid = true

      return isValid
    },
    async submitForm() {
      this.isLoading = true
      if (this.validateForm()) {
        const data = new FormData()
        for (let i in this.formData) {
          if (this.formData[i]) {
            data.append(i, this.formData[i])
          }
        }

        await reqUpdatePromo(this.$route.params.id, data)
          .then(response => {
            this.isLoading = false
            this.formErrors = {}

            this.showToast(
              'success',
              'CheckIcon',
              'Berhasil',
              response.data.message || 'Promo Berhasil Disimpan',
            )

            this.formData = {
              title: '',
              path: '',
            };
            this.$router.push({
              name: checkAuthorizeRole("promos.index"),
              params: {
                id: response.data.data.id,
              },
            });
          })
          .catch(error => {
            this.isLoading = false
            this.formErrors = error.response.data.errors

            this.showToast(
              'danger',
              'AlertTriangleIcon',
              'Gagal',
              error.response.data.message || 'User Gagal Disimpan',
            )
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.line-height-small {
  line-height: 1.5rem;
}

.small-gap-right {
  margin-right: 8px;
}

.small-gap-top {
  margin-top: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}
</style>
